/*

제휴사 로고가 배너형태로 움직이는 컴포넌트

사용 : WEV, 비즈니스 about

*/
import React from 'react'
import { COMPANY_PARTNER_DATA, MEMBERSHIP_ROAMING_DATA } from '@src/constant/company'
import * as S from './Partners.styled'

const Partners = () => {
  const SlidingBanner = ({ data, className }: { data: { text: string; img_url: string }[]; className?: string }) => (
    <div className="slide_container">
      <ul>
        <div className={`slide original ${className}`}>
          {data.map((el) => (
            <li key={el.text}>
              <S.LogoItem>
                <img src={el.img_url} />
              </S.LogoItem>
            </li>
          ))}
        </div>
        <div className={`slide clone ${className}`}>
          {data.map((el) => (
            <li key={el.text}>
              <S.LogoItem>
                <img src={el.img_url} />
              </S.LogoItem>
            </li>
          ))}
        </div>
      </ul>
    </div>
  )

  return (
    <S.LollingWrapper>
      <SlidingBanner data={[...COMPANY_PARTNER_DATA, ...MEMBERSHIP_ROAMING_DATA].slice(0, 20)} />
      <SlidingBanner data={[...COMPANY_PARTNER_DATA, ...MEMBERSHIP_ROAMING_DATA].slice(20, 40)} className="delay" />
      <SlidingBanner data={[...COMPANY_PARTNER_DATA, ...MEMBERSHIP_ROAMING_DATA].slice(40, 60)} />
    </S.LollingWrapper>
  )
}

export default Partners
