import Image from 'next/legacy/image'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import { WEV_APP_SCREENS } from '../../constants'
import { AppleLogo, Download, GooglePlayLogo } from '@src/assets/icons'
import { useTranslation } from 'next-i18next'
import parse from 'html-react-parser'
import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { useResponsive } from '@src/hooks/useResponsive'
import { Autoplay, EffectCoverflow } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { nanoid } from '@reduxjs/toolkit'
import { newlineParse } from '@src/utils/mobileParse'
import * as S from './WevApp.styled'

const WevApp = () => {
  const { t } = useTranslation('main')
  const { isMobile } = useResponsive(768)
  gsap.registerPlugin(ScrollTrigger)

  const sectionRef = useRef(null)
  const triggerRef = useRef(null)

  useEffect(() => {
    if (!isMobile) {
      ScrollTrigger.config({
        autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load',
      })
      const pin = gsap.fromTo(
        sectionRef.current,
        { translateX: 100 },
        {
          translateX: -1200,
          ease: 'none',
          scrollTrigger: {
            trigger: triggerRef.current,
            start: 'top 10%',
            end: 'bottom center',
            scrub: 3,
            pin: true,
          },
        },
      )

      return () => {
        pin.kill()
      }
    }
  }, [])

  return (
    <S.Content>
      <div id="intro" data-aos="fade-up">
        <h2>{parse(t('wevApp.title'))}</h2>
        <p>{newlineParse(isMobile, t('wevApp.subtitle'))}</p>
      </div>
      <S.Download data-aos="fade-up">
        <div>
          <div id="wevLogo">
            <div style={{ width: '83px', height: '22px', position: 'relative' }}>
              <Image src="/images/wev/wev_logo_white.png" layout="fill" objectFit="contain" />
            </div>
          </div>
          <p className="mt-4">WEV</p>
        </div>

        <div className="flex flex-col w-full space-y-2">
          <button
            className="downloadButton space-x-4"
            onClick={() => window.open('https://play.google.com/store/apps/details?id=com.elelectric.wev')}
          >
            <GooglePlayLogo />
            <span>Google Play</span>
            <Download />
          </button>
          <button
            className="downloadButton space-x-4"
            onClick={() => window.open('https://apps.apple.com/app/1673610987')}
          >
            <AppleLogo />
            <span>Apple Store</span>
            <Download />
          </button>
        </div>

        <div id="qr">
          <Image src="/images/main/renewal/wevApp/wev_download_qr_code.png" width={98} height={98} />
          <p>{parse(t('wevApp.qrHelper'))}</p>
        </div>
      </S.Download>

      {isMobile ? (
        <Swiper
          autoplay={{ delay: 1000 }}
          // loop
          effect="coverflow"
          grabCursor
          centeredSlides
          slidesPerView="auto"
          modules={[EffectCoverflow, Autoplay]}
          coverflowEffect={{
            rotate: 30,
            depth: 80,
            //   modifier: 6,
            slideShadows: false,
          }}
          data-aos="fade-up"
        >
          {WEV_APP_SCREENS.map(({ src, title }) => (
            <SwiperSlide key={title}>
              {({ isActive }) => (
                <>
                  <div
                    style={{
                      width: 260,
                      height: 570,
                      position: 'relative',
                    }}
                  >
                    {isActive ? null : <div id="swiperSlideBlur" />}
                    <img src={src} />
                  </div>
                  {isActive ? <p className="mt-4">{t(`wevApp.screenshots.${title}`)}</p> : null}
                </>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="h-full" data-aos="fade-up">
          <S.Slider ref={triggerRef}>
            <div ref={sectionRef} className="flex flex-row  gap-[30px] ml-[50%]">
              {WEV_APP_SCREENS.map((el) => (
                <S.ImageItem key={nanoid()}>
                  <div>
                    <img src={el.src} />
                  </div>

                  <p>{t(`wevApp.screenshots.${el.title}`)}</p>
                </S.ImageItem>
              ))}
              <S.ImageItem />
            </div>
          </S.Slider>
        </div>
      )}
    </S.Content>
  )
}

export default WevApp
